import { Container, Content } from "./styles";
import logoImg from '../../assets/logo.png';
import { FiMenu } from 'react-icons/fi'
import { GrClose } from "react-icons/gr";
import { useNavigate, useLocation } from "react-router-dom";

interface HeaderProps {
    handleToggleMenu: () => void,
    hideHeaderPaths: string[];
    isMenuOpen: boolean
}

export function Header(props: HeaderProps) {
    const navigate = useNavigate()
    const redirectTo = (route: string) => {
        navigate(route);
    }

    const location = useLocation();
    console.log('currentPath ->', location.pathname);
    if(props.hideHeaderPaths.includes(location.pathname)) {
        return(<></>)
    }


    return(
        <Container>
            <Content>
                <img src={logoImg} alt="Limpeza Absoluta" onClick={() => redirectTo('/')}/>
                {props.isMenuOpen 
                    ? <GrClose className="btnMenu" onClick={props.handleToggleMenu}/>
                    : <FiMenu className="btnMenu" onClick={props.handleToggleMenu}/>
                }
            </Content>
        </Container>

    )
}