import { DrawerStyled, GlobalStyle } from './styles/global';
import { useState } from 'react';
import { Header } from './components/Header';
import { Container, MainSection,SecundSection,ThirdSection,FourthSection,FifthSection,WhatsappButton,Footer } from './AppMvpStyles';
import { BsEmojiSmile, BsCheck2All, BsShieldCheck } from 'react-icons/bs';
import { AiFillInstagram, AiFillFacebook, AiOutlineGoogle, AiOutlineInfoCircle } from 'react-icons/ai';
import { IoLogoWhatsapp, IoMdMail } from 'react-icons/io';
import manWorking from './assets/man_working.jpg';
import womanExercising from './assets/woman_exercising.jpg';
import familyPlaying from './assets/family_playing.jpg';
import whatsappIcon from './assets/whatsapp_icon.png';
import { Tooltip } from "antd";
import { useRef } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';


export function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleToggleMenu() {
      setIsMenuOpen(!isMenuOpen);
  }
  
  function closeMenu() {
      setIsMenuOpen(false);
  }

  const resultsRef = useRef<any>(null);

  return (
    <BrowserRouter>
      <Header 
        handleToggleMenu={() => handleToggleMenu()} 
        isMenuOpen={isMenuOpen}
        hideHeaderPaths={['/login', '/register']}
        />
      <DrawerStyled
        open={isMenuOpen}
        onClose={closeMenu}
        className="mainMenu"
        placement='top'
        closable={false}
        >
        <a className='menuItem' onClick={()=> {
            setIsMenuOpen(false)
            window.location.href = '';
        }}>Início</a>
        <a href="https://wa.me/5511911691066?text=Ol%C3%A1%21+Gostaria+de+fazer+um+or%C3%A7amento" target="blank" className='menuItem' onClick={()=> setIsMenuOpen(false)}>Orçamento</a>
        <a className='menuItem' onClick={()=> {
            setIsMenuOpen(false); 
            window.location.href = '#results';
            window.scrollBy(0, -100);
        }}>Resultados</a>
        <a className='menuItem' onClick={()=> {
            setIsMenuOpen(false)
            window.location.href = '#mission';
            window.scrollBy(0, -100);
        }}>Nossa Missão</a>
        <a href="#guarantees" className='menuItem' onClick={()=>{
            setIsMenuOpen(false)
            window.location.href = '#guarantees';
            window.scrollBy(0, -100);
        }}>Garantias</a>
      </DrawerStyled>


      <GlobalStyle/>
      <Routes>
        <Route path='/' element={
            <Container>
                <MainSection>
                    <h1>Contrate faxineiras de forma rápida,<br/> prática e segura</h1>
                    <p>Enquanto você aproveita seu dia, o serviço pesado fica com a gente!</p>
                    {/* <button className="mainButton" onClick={() => redirectTo('/services')}>Solicitar Orçamento</button> */}
                    <a className="mainButton" href="https://wa.me/5511911691066?text=Ol%C3%A1%21+Gostaria+de+fazer+um+or%C3%A7amento" target="blank">Solicitar Orçamento</a>
                </MainSection>
                <SecundSection id="results" ref={resultsRef}>
                    <div className="resultsWrapper">
                        <div className="result">
                            <h2>+5.000</h2>
                            <p>Clientes Atendidos</p>
                        </div>
                        <div className="result">
                            <h2>96%</h2>
                            <p>Avaliações 5 estrelas</p>
                        </div>
                        <div className="result">
                            <h2>+200</h2>
                            <p>Diaristas cadastradas</p>
                        </div>
                    </div>
                    <div className="cta">
                        <p>
                            Esses números são apenas uma pequena demonstração do nosso compromisso e do 
                            reconhecimento que recebemos dos nossos clientes.<br/><br/> 
    
                            É uma enorme alegria para nós poder cuidar da sua casa enquanto você usa o seu 
                            tempo para fazer o que realmente é importante <span>para você!</span>
                        </p>
    
                        {/* <button className="mainButton" onClick={() => redirectTo('/services')}>Solicitar Orçamento</button> */}
                        <a className="mainButton" href="https://wa.me/5511911691066?text=Ol%C3%A1%21+Gostaria+de+fazer+um+or%C3%A7amento" target="blank">Solicitar Orçamento</a>
                    </div>
    
                </SecundSection>
                <ThirdSection id="mission">
                    <h2>
                        Casa Limpa, Mente Livre:<br/>
                        <span>Nossa Missão é sua Satisfação</span>
                    </h2>
                    <h3>Dedique seu tempo à algo mais importante!</h3>
                    <div className="storyBlock">
                        <p>
                            Seja sincero com você mesmo, a limpeza da sua casa é 
                            a coisa mais importante que você tem para fazer? <br/> Que tal  usar esse tempo para adiantar seu trabalho ou 
                            quem sabe tirar aquele projeto do papel? 
                        </p>
                        <img src={manWorking} alt="Homem trabalhando em casa com notebook" />
                    </div>
                    <div className="storyBlock">
                        <img src={womanExercising} alt="Homem trabalhando em casa com notebook" />
                        <p>
                            Você tem dedicado tempo suficiente a sua saúde e seus cuidados pessoais?<br/> Praticar atividades físicas
                            seria uma ótima opção para aproveitar o tempinho que
                            você irá ganhar. 
                        </p>
                    </div>
                    <div className="storyBlock">
                        <p>
                            E que tal tirar um tempo para ficar de bobeira e brincar com 
                            as crianças e com os pets?<br/> Além de aliviar o estresse do dia a dia, essas atividades
                            ajudam a estimular o seu lado criativo.
                        </p>
                        <img src={familyPlaying} alt="Homem trabalhando em casa com notebook" />
                    </div>
                </ThirdSection>
                <FourthSection>
                    <h2>
                        O mais legal é que com o tempo 
                        que vai sobrar você vai poder 
                        fazer tudo isso e muito mais!
                    </h2>
    
                    {/* <button className="mainButton" onClick={() => redirectTo('/services')}>Solicitar Orçamento</button> */}
                    <a className="mainButton" href="https://wa.me/5511911691066?text=Ol%C3%A1%21+Gostaria+de+fazer+um+or%C3%A7amento" target="blank">Solicitar Orçamento</a>
                </FourthSection>
                <FifthSection id="guarantees">
                    <div>
                        <Tooltip 
                            title="Chega de correr atrás de recomendações de faxineiras, nós temos as melhores profissionais para você!" 
                            trigger="click"
                            placement="top"
                        >
                            <BsEmojiSmile fontSize="2rem"/>
                            <h3>Praticidade <AiOutlineInfoCircle/></h3>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip 
                            title="Nossas profissionais são capacitadas e constantemente avaliadas, garantindo a qualidade do serviço prestado." 
                            trigger="click"
                            placement="top"
                        >
                            <BsCheck2All fontSize="2rem" />
                            <h3>Qualidade <AiOutlineInfoCircle/></h3>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip 
                            title="Temos um processo rigido de avaliações para a contratação das profissionais, tudo para garantir a sua segurança." 
                            trigger="click"
                            placement="top"
                        >
                            <BsShieldCheck fontSize="2rem" />
                            <h3>Segurança <AiOutlineInfoCircle /></h3>
                        </Tooltip>
                    </div>
                </FifthSection>
                {/* aguardar atualização das redes sociais para poder ativar o rodapé com os links */}
                {/* <Footer>
                    <h2>Nossas redes e canais de comunicação</h2>
                    <div>
                        <a href=""><AiFillInstagram color="#fff"/></a>
                        <a href=""><AiFillFacebook color="#fff"/></a>
                        <a href=""><AiOutlineGoogle color="#fff"/></a>
                        <a href=""><IoLogoWhatsapp color="#fff"/></a>
                        <a href=""><IoMdMail color="#fff"/></a>
                    </div>
                </Footer> */}
    
                <WhatsappButton id="whatsappButton" href="https://wa.me/5511911691066?text=Ol%C3%A1%21+Gostaria+de+fazer+um+or%C3%A7amento" target="blank"><img src={whatsappIcon} alt="Icone do whatsapp" /></WhatsappButton>
            </Container>
        }/>
      </Routes>
      <GlobalStyle/>
    </BrowserRouter>
  )
}

