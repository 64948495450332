import styled from "styled-components";

export const Container = styled.header`
    background-color: #fff;
    width: 100%;

    position: fixed;
    top: 0px;
    z-index: 100;

    box-shadow: 0 1px 10px -6px rgb(0 0 0 / 42%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 4px 5px -2px rgb(0 0 0 / 10%);
    `

export const Content = styled.div`
    max-width: 1120px;
    margin: 0 auto;
    
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    img {
        width: 4rem;
        height: 4rem;
        cursor: pointer;
    }
    
    .btnMenu {
        cursor: pointer;
        height: 2rem;
        width: 2rem;
    }
`